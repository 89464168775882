@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
    border: 1px solid red;
} */

.logo-company {
    width: 60%;
}

.expediente-l {
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
}

.expediente-r {
    clip-path: polygon(9.5% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.logo {
    float: left;
}

.exp-img {
    width: 145px;
}

.exp-img:hover {
    display: none;
    transition: 0.3s;
}

.stripes-nav {
    float: left;
    width: 52%;
    height: 4em;
}

@media only screen and (max-width: 768px) {
    .expediente-l,
    .expediente-r {
        clip-path: none;
    }
    .stripes-nav {
        width: 42%;
    }
    .logo {
        width: 150px;
        float: left;
    }
}

.swiper-button-prev,
.swiper-button-next {
    height: 45px;
    width: 45px;
    background: #3e9c55;
    border-radius: 100%;
    color: white;
    padding: 1em 1.3em;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 14px;
}

.swiper-pagination-bullet-active {
    background: #3e9c55;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 25px;
    right: 30px;
    background-color: #3e9c55;
    color: #fff !important;
    border-radius: 50px;
    text-align: center;
    font-size: 40px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    z-index: 100;
}
  
.my-float {
    margin-top: 10px;
    margin-left: 0px;
}

.container-image-post img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.post-by {
    font-size: 10px;
}

.sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}